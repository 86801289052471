import React, { useEffect, useContext } from "react";
import SEO from "../components/SEO";
import StickyCarousel from "@src/components/partials/privacy/StickyCarousel";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import Letter from "@src/components/partials/privacy/Letter";
import PrivacyHero from "@src/components/partials/privacy/PrivacyHero";
import PrivacyFeatures from "@src/components/partials/privacy/PrivacyFeatures";

const PrivacyCenterPage = () => {
  const data = useStaticQuery(graphql`
    query {
      privacyStar: file(relativePath: { eq: "privacy/privacy-star.png" }) {
        publicURL
      }
      signature: file(relativePath: { eq: "privacy/signature.png" }) {
        publicURL
      }
      founders: file(relativePath: { eq: "privacy/founders.jpg" }) {
        publicURL
      }
      directus {
        page_privacy_2024 {
          Cards
          Cards_heading
          Carousel_cards
          Carousel_heading
          Feature_1
          Feature_1_text
          Feature_2
          Feature_2_text
          Hero_heading
          Hero_text
          Letter_heading
          Letter_part_1
          Letter_part_2
          SEO_description
          SEO_title
          Hero_background {
            filename_disk
          }
          Feature_1_image {
            filename_disk
          }
          Feature_2_image {
            filename_disk
          }
        }
      }
    }
  `);

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    logEvent({
      eventName: "web.external.privacycenter.page_load",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const t = useContext(TranslationContext);

  const translatedCarouselContent = data.directus.page_privacy_2024.Carousel_cards.map((content, index) => ({
    imageSrc: content.image,
    mobileSrc: content.mobile_image,
    heading: t.translate(`directus.page_privacy_2024.Carousel_cards.heading_${index + 1}`),
    paragraph: t.translate(`directus.page_privacy_2024.Carousel_cards.text_${index + 1}`),
  }));

  const translatedCardsContent = data.directus.page_privacy_2024.Cards.map((content, index) => ({
    icon: content.icon,
    heading: t.translate(`directus.page_privacy_2024.Carousel_cards.heading_${index + 1}`),
    paragraph: t.translate(`directus.page_privacy_2024.Carousel_cards.text_${index + 1}`),
    detail_text: "nada",
  }));

  return (
    <>
      <SEO
        title="page_titles.privacycenter"
        description="page_descriptions.privacycenter"
        image="https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo Privacy Center",
          description:
            "See how ClassDojo keeps its community safe through privacy by design and complying with FERPA and COPPA",
          image: "https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png",
        }}
      />
      <PrivacyHero
        heading={t.translate("directus.page_privacy_2024.Hero_heading")}
        bgImage={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Hero_background.filename_disk}`}
      />
      <Letter
        cards={data.directus.page_privacy_2024.Cards}
        signature={data.signature.publicURL}
        founders={data.founders.publicURL}
      />
      <StickyCarousel content={translatedCarouselContent} image={data.privacyStar.publicURL} />
      <PrivacyFeatures
        feature1={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Feature_1_image.filename_disk}`}
        feature2={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Feature_2_image.filename_disk}`}
      />
    </>
  );
};

export default PrivacyCenterPage;
